import styled from 'styled-components'

import { MainTitle, BodyText, MainSubtitle, CountryTitle } from '../../common/text-styled'
import { media } from '../../../constants/media'

export const Title = styled(MainTitle)`
  text-align: center;
  @media ${media.medium.query} {
    text-align: left;
    font-size: 7rem;
    letter-spacing: 0.01rem;
  }
`;

export const Subtitle = styled(MainSubtitle)`
  text-align: center;
  margin-top: 40px;
  margin-left: 45px;
  @media ${media.medium.query} {
    margin-top: 60px;
    margin-left: 25px;
    text-align: left;
  }
`;

export const P = styled(BodyText)`
  margin: 20px 45px;
  font-size: 1.7rem;
  line-height: 3rem;
  @media ${media.medium.query} {
    margin: 20px 25px;
    font-size: 2.7rem;
    line-height: 4.2rem;
  }
`;

export const Quote = styled(CountryTitle)`
  font-family: ${props => props.theme.fonts.solway};
  font-style: italic;
  font-weight: bold;
  margin: 20px 45px;
  font-size: 1.5rem;
  line-height: 3rem;
  @media ${media.medium.query} {
    font-style: italic;
    font-weight: bold;
    margin: 20px 0;
    font-size: 2.5rem;
    line-height: 4.2rem;
  }
`;

export const Img = styled.img`
  margin: 2rem 0;
  width: 100%;
  max-width: ${media.small.maxWidth};
  @media ${media.medium.query} {
    border-radius: 10px;
    max-width: ${media.medium.maxWidth};
  }
`;

export const MapContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: ${media.small.maxWidth};
  @media ${media.medium.query} {
    max-width: ${media.medium.maxWidth};
  }
`;