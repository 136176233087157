import React from 'react'

import * as S from '../about-styled'

export const FoodSection = () => (
  <>
    <S.Subtitle>Food</S.Subtitle>

    <S.P>
      Left to my own devices, you are not going to find me at a fine dining establishment. I'm sorry, but I have no interest in going to the 198 michelin star rated, overpriced, water front restaurant with a dress code.
    </S.P>

    <S.P>
      I'm always in search of the family run hole-in-the-wall that uses their great-great-grandmother's recipe and ingredients sourced from their cousin's farm.
    </S.P>

    <S.P>
      You'll either find me respectfully enjoying the homely meal or on my way back from the bar, scarfing down an excessive but necessary amount of grease in the form of food.
    </S.P>

    <S.P>
      Pizza in NY, döner kebab in Berlin, bun bo hue in Hue, al pastor tacos in Mexico City, biryani in Hyderabad, mole in Oaxaca, khao soi in Chiang Mai......I can go on and on. I'll happily take any of those dishes over the set menu from the #1 rated restaurant in the world.
    </S.P>

  </>
)