import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { AboutComponent } from './about-component'

const About = (props) => (
  <StaticQuery
    query={visitedCountriesQuery}
    render={data => {
      const visitedCountries = data.site.siteMetadata.visitedCountries.map(isoCode => isoCode);

      return (
        <AboutComponent
          visitedCountries={visitedCountries}
          {...props}
        />
      );
    }}
  />
)

const visitedCountriesQuery = graphql`
  query visitedCountriesQuery {
    site {
      siteMetadata {
        visitedCountries
      }
    }
  }
`;

export { About }