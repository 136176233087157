import React from 'react'
import PropTypes from 'prop-types'

import { Map } from '../../../common/map/map-container'
import * as S from '../about-styled'

const MapSection = ({ visitedCountries }) => (
  <>
    <S.Subtitle>Where have I been?</S.Subtitle>

    <S.P>
      I left my job in 2017 with a one-way ticket to South America and I've been traveling on and off ever since.
    </S.P>

    <S.P>
      <strong>
        {visitedCountries.length} countries, and counting.
      </strong>
    </S.P>

    <S.MapContainer>
      <Map highlightedCountries={visitedCountries} />
    </S.MapContainer>
  </>
)

MapSection.propTypes = {
  visitedCountries: PropTypes.array.isRequired,
}

export { MapSection }