import React from 'react'

import * as aboutMeImg from '../assets/me.jpg'
import * as S from '../about-styled'

export const IntroSection = () => (
  <>
    <S.Img
      src={aboutMeImg}
      alt="Me"
    />

    <S.P>
      I'm Justin and I was born and raised in New York.
    </S.P>

    <S.P>
      I travel a lot and always leave a country fatter than when I entered it. I built this website to share all the recipes I've learned while abroad.
    </S.P>

    <S.P>
      After feeling frustrated with other recipe websites, I vow to never place mountains of text and advertisements before showing an ingredient list and directions.
    </S.P>
  </>
)