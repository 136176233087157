import React from 'react'

import Layout from '../components/common/layout/layout-component'
import SEO from '../components/common/seo'
import { About } from '../components/pages/about/about-container'
import { EmailSubscribeForm } from '../components/common/email-subscribe-form/email-subscribe-form-container'

const AboutPage = () => (
  <Layout>

    <SEO
      title="About"
      keyword={['about', 'about me', 'cooking', 'traveling', 'travel']}
    />

    <About />

    <EmailSubscribeForm showLabel />

  </Layout>
)

export default AboutPage;