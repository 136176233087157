import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from 'react-fade-in'

import { IntroSection } from './sections/intro-section'
import { TravelSection } from './sections/travel-section'
import { FoodSection } from './sections/food-section'
import { MapSection } from './sections/map-section'
import * as S from './about-styled'

const AboutComponent = ({ visitedCountries }) => (
  <FadeIn>

    <S.Title>About</S.Title>

    <IntroSection />

    <FoodSection />

    <MapSection visitedCountries={visitedCountries} />

    <TravelSection />

  </FadeIn>
)

AboutComponent.propTypes = {
  visitedCountries: PropTypes.array.isRequired,
}

export { AboutComponent }