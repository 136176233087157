import React from 'react'

import * as S from '../about-styled'

export const TravelSection = () => (
  <>
    <S.Subtitle>Travel</S.Subtitle>

    <S.P>
      I have two goals for every place I visit:
      <ol>
        <li>Try every regional dish</li>
        <li>Learn at least one new recipe</li>
      </ol>
    </S.P>

    <S.Quote>
      "You take something with you. Hopefully, you leave something good behind." - Anthony Bourdain
    </S.Quote>

    <S.P>
      Words can't describe how grateful I feel for all the people I've met on the road:
    </S.P>

    <S.P>
      The fellow travelers I tirelessly dragged along to find some obscure regional dish.
    </S.P>

    <S.P>
      The people who invited me into their kitchens to cook along side them.
    </S.P>

    <S.P>
      The people who shared their family recipes with me.
    </S.P>

    <S.P>
      They have, collectively, left me with this website. I can only hope I left something good for them as well.
    </S.P>
  </>
)